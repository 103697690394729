import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';

// // Check Before Login OLD
// export const authGuard: CanActivateFn = (route, state) => {
//   if (!inject(AuthService).getToken()) {
//     inject(Router).navigate(['/vendor/login'])
//     return false;
//   }
//   return true;
// };

// Check Before Login New
export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.getToken()) {
    router.navigate(['/vendor/login']);
    return false; // Block navigation if the user is not authenticated.
  }

  return true; // Allow navigation if the user is authenticated.
};

// Check after Login OLD
// export const authGuardafterLogin: CanActivateFn = (route, state) => {
//   if (inject(AuthService).getToken()) {
//     debugger
//     if ((route.routeConfig?.path == 'vendor' || route.routeConfig?.path == 'vendor/login') && !(route.queryParams.token && route.queryParams.lang)) {
//       inject(Router).navigate(['/dashboard'])
//       return true;
//     } else if (route.queryParams.token && route.queryParams.lang) {
//       localStorage.clear();
//       inject(Router).navigateByUrl(state.url)
//     }
//   }
//   return true;
// };
// Check after Login NEW
export const authGuardafterLogin: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const hasToken = authService.getToken();
  const isVendorRoute = ['vendor', 'vendor/login'].includes(route.routeConfig?.path || '');
  const hasQueryParams = !!(route.queryParams.token && route.queryParams.lang);

  if (hasToken && isVendorRoute) {
    if (!hasQueryParams) {
      router.navigate(['/dashboard']);
      return false; // Block navigation to vendor routes without required query params.
    }

    if (hasQueryParams) {
      if (environment.production) {
        // Code for DIff domain
        localStorage.clear();
      } else {
        // Code for same domain
        localStorage.removeItem('token');
        localStorage.removeItem('selected_location_id');
      }
      router.navigateByUrl(state.url); // Redirect to the same URL with fresh state.
      return false; // Block the initial navigation.
    }
  }

  return true; // Allow navigation if no conditions are met.
};

