// Spain
export const locale = {
  lang: 'es',
  data: {
    TRANSLATOR: {
      SELECT: 'Selecciona tu idioma',
    },
    MENU: {
      NEW: 'Nuevo',
      ACTIONS: 'Acciones',
      CREATE_POST: 'Crear nueva publicación',
      PAGES: 'Páginas',
      FEATURES: 'Características',
      APPS: 'Aplicaciones',
      DASHBOARD: 'Panel de control',
      PROFILE: 'Perfil',
      USER_MANAGEMANET: 'Gestión de usuarios',
      USER_ROLE_MANAGEMANET: 'Gestión de roles de usuario',
      DEVICE_MANAGEMANET: 'Gestión de dispositivos',
      COUPON_MANAGEMANET: 'Gestión de cupones',
      SELECT_LOCATION: 'Seleccionar ubicación',
    },
    HEADER_MENU: {
      THEME: {
        LIGHT: 'Claro',
        DARK: 'Oscuro',
        SYSTEM: 'Sistema',
      },
      PROFILE_MENU: {
        LANGUAGE: 'Idioma',
        SWITCH_TO_USER: 'Cambiar a usuario',
        SIGNOUT: 'Cerrar sesión',
      }
    },
    SIGNIN_MODULE: {
      SIGNIN: 'Iniciar sesión',
      EMAIL: 'Correo electrónico',
      EMAIL_REQUIRED: 'El correo electrónico es obligatorio',
      EMAIL_INVALID: 'El correo electrónico no es válido',
      EMAIL_ATLEAST3_SYMBOLS: 'El correo electrónico debe tener al menos 3 caracteres',
      EMAIL_MAX_VALIDATION: 'El correo electrónico no debe exceder los 360 caracteres',
      PHONE: 'Teléfono',
      PASSWORD: 'Contraseña',
      PASSWORD_REQUIRED: 'La contraseña es obligatoria',
      FORGET_PASSWORD_LINK: '¿Olvidaste tu contraseña?',
    },
    FORGOT_PASSWORD_MODULE: {
      FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
      EMAIL: 'Correo electrónico',
      PHONE: 'Teléfono',
      EMAIL_REQUIRED: 'El correo electrónico es obligatorio',
      EMAIL_INVALID: 'El correo electrónico no es válido',
      EMAIL_ATLEAST3_SYMBOLS: 'El correo electrónico debe tener al menos 3 caracteres',
      EMAIL_MAX_VALIDATION: 'El correo electrónico no debe exceder los 360 caracteres',
      FORGOT_ERROR: 'Lo siento, parece que hay algunos errores detectados, por favor intenta de nuevo.',
      PASSWORD_RESET_LINK_SENT: 'Se ha enviado un enlace para restablecer la contraseña a tu correo electrónico. Por favor, revisa tu bandeja de entrada.',
      SUBMIT: 'Enviar',
    },
    RESET_PASSWORD_MODULE: {
      RESET_PASSWORD: 'Restablecer la contraseña',
      NEW_PASSWORD: 'Nueva contraseña',
      NEW_PASSWORD_REQUIRED: 'La nueva contraseña es obligatoria',
      PASSWORD_MUST_6CHAR_LONG: 'La contraseña debe tener al menos 6 caracteres',
      PASSWORD_COMPLEXITY_REQUIREMENTS: 'La contraseña debe tener al menos una letra minúscula, una letra mayúscula, un dígito y un carácter especial',
      CONFIRM_PASSWORD: 'Confirmar contraseña',
      CONFIRM_PASSWORD_REQUIRED: 'La confirmación de la contraseña es obligatoria',
      PASSWORD_NOT_MATCH: 'Las contraseñas no coinciden',
    },
    PLEASE_WAIT: 'Por favor, espera...',
    SAVE_CHANGES: 'Guardar cambios',
    COUNTINUE: 'Continuar',
    BACK: 'Atrás',
    SUBMIT: 'Enviar',
    CANCEL: 'Cancelar',
    EDIT: 'Editar',
    VIEW: 'Ver',
    DELETE: 'Eliminar',
    PAGES: {
      ROW_PER_PAGE: 'Filas por página',
      TOTAL_RECORD: 'Registros totales',
      NEXT: 'Siguiente',
      PREVIOUS: 'Anterior',
    },
    NO_RECORDS: 'No hay registros disponibles',
    YES: 'Sí',
    NO: 'No',
    VENDOR_PROFILE: {
      PROFILE: 'Perfil',
      CHANGE_PASSWORD: 'Cambiar contraseña',
    },
    PROFILE_DETAILS_TAB: {
      PROFILE_DETAILS: 'Detalles del perfil',
      NAME: 'Nombre',
      EMAIL_OR_PHONE: 'Correo electrónico o teléfono',
    },
    CHANGE_PASSWORD_TAB: {
      OLD_PASSWORD: 'Contraseña anterior',
      OLD_PASSWORD_REQUIRED: 'La contraseña anterior es obligatoria',
      NEW_PASSWORD: 'Nueva contraseña',
      NEW_PASSWORD_REQUIRED: 'La nueva contraseña es obligatoria',
      PASSWORD_MUST_6CHAR_LONG: 'La contraseña debe tener al menos 6 caracteres',
      PASSWORD_COMPLEXITY_REQUIREMENTS: 'La contraseña debe tener al menos una letra minúscula, una letra mayúscula, un dígito y un carácter especial',
      CONFIRM_PASSWORD: 'Confirmar contraseña',
      CONFIRM_PASSWORD_REQUIRED: 'Confirmar contraseña es obligatorio',
      PASSWORD_NOT_MATCH: 'Las contraseñas no coinciden',
    },
    USER_MANAGEMENT: {
      SEARCH_USERS: 'Buscar usuarios',
      USERS: 'Usuarios',
      NEW_USER: 'Nuevo usuario',

      CONFIRM_DELETE_MSG: '¿Estás seguro de que deseas eliminar a este usuario?',
      USER_TABLE: {
        NAME: 'Nombre',
        EMAIL_PHONE: 'Correo electrónico/teléfono',
        ROLE: 'Rol',
        ACTIONS: 'Acciones',
      },
      VIEW_USERS: {
        USER_DETAIL: 'Detalles del usuario',
        NAME: 'Nombre',
        EMAIL: 'Correo electrónico',
        ROLE_NAME: 'Nombre del rol',
        MODULE_WISE_ROLE_PERMISSION: 'Permisos de rol por módulo',
        ADD: 'Añadir',
        EDIT: 'Editar',
        VIEW: 'Ver',
        DELETE: 'Eliminar',
      },
      ADD_USER: 'Añadir usuario',
      EDIT_USER: 'Editar usuario',
      DELETE_USER: 'Eliminar usuario',
      ADD_EDIT: {
        NAME: 'Nombre',
        EMAIL: 'Correo electrónico',
        ROLE: 'Rol',
        SELECT_ROLE: 'Seleccionar rol',
      }
    },
    USER_ROLE_MANAGEMENT: {
      USER_ROLES: 'Roles de usuario',
      ADD_USER_ROLE: 'Añadir rol de usuario',
      CONFIRM_DELETE_MSG: '¿Estás seguro de que deseas eliminar este rol de usuario?',
      ROLE_TABLE: {
        NAME: 'Nombre',
        DESCRIPTION: 'Descripción',
        ACTIONS: 'Acciones',
      },
      DELETE_USER_ROLE: 'Eliminar rol de usuario',
      ADD_ROLE: 'Añadir rol',
      EDIT_ROLE: 'Editar rol',
      ADD_EDIT: {
        NAME: 'Nombre',
        DESCRIPTION: 'Descripción',
        MODULE_WISE_ROLE_PERMISSION: 'Permisos de rol por módulo',
        ADD: 'Añadir',
        EDIT: 'Editar',
        VIEW: 'Ver',
        DELETE: 'Eliminar',
      }
    },
    DEVICE_MANAGEMENT: {
      DEVICES: 'Dispositivos',
      SEARCH_DEVICES: 'Buscar dispositivos',
      CONFIRM_DELETE_MSG: '¿Estás seguro de que deseas eliminar este dispositivo?',
      DEVICE_TABLE: {
        NAME: 'Nombre',
        STATUS: 'Estado',
        BATTERY: 'Batería',
        LOCATION: 'Ubicación',
        LAST_UPDATE: 'Última actualización',
        ACTIONS: 'Acciones',
      },
      ADD_EDIT: {
        NAME: 'Nombre',
        BATTERY: 'Batería',
        STATUS: 'Estado',
        LOCATION: 'Ubicación',
        SELECT_LOCATION: 'Seleccionar ubicación',
      },
      SELECT_STATUS: 'Seleccionar estado',
      ACTIVE: 'Activo',
      INACTIVE: 'Inactivo',
      SORT_BY: 'Ordenar por',
      ASC: 'Ascendente',
      DESC: 'Descendente',
      DELETE_DEVICE: 'Eliminar dispositivo',
      ADD_DEVICE: 'Añadir dispositivo',
      EDIT_DEVICE: 'Editar dispositivo',
    },
    COUPON_MANAGEMENT: {
      COUPONS: 'Cupones',
      CONFIRM_DELETE_MSG: '¿Estás seguro de que deseas eliminar este cupón?',
      COUPON_TABLE: {
        TITLE: 'Título',
        CODE: 'Código',
        AMOUNT_PERCENT: 'Monto o porcentaje',
        COUPON_TYPE: 'Tipo de cupón',
        NUMBER_OF_USED: 'Número de usos',
        STATUS: 'Estado',
        ACTIONS: 'Acciones',
      },
      ADD_EDIT: {
        TITLE: 'Título',
        CODE: 'Código',
        AMOUNT_PERCENT: 'Monto o porcentaje',
        START_DATE: 'Fecha de inicio',
        END_DATE: 'Fecha de finalización',
        NUMBER_OF_USE: 'Número de usos',
        TYPE_OF_AMOUNT: 'Tipo de monto',
        AMOUNT: 'Monto',
        PLEASE_SELECT_BRANCH_LOCATION: 'Por favor, selecciona la ubicación de la sucursal',
      },
      SELECT_TYPE: 'Seleccionar tipo',
      FIXED_AMOUNT: 'Monto fijo',
      PERCENTAGE_AMOUNT: 'Monto en porcentaje',
      SELECT_COUPON_TYPE: 'Seleccionar tipo de cupón',
      COUPON: 'Cupón',
      GIFT_CARD: 'Tarjeta de regalo',
      FILTER_BY: 'Filtrar por',
      ALL: 'Todos',
      UTILIZED: 'Utilizados',
      SELECT_STATUS: 'Seleccionar estado',
      ENABLE: 'Habilitar',
      DISABLE: 'Deshabilitar',
      ADD_COUPON: 'Añadir cupón',
      EDIT_COUPON: 'Editar cupón',
      DELETE_COUPON: 'Eliminar cupón',
    }

  }
};
