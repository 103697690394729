// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      PROFILE: 'Profile',
      USER_MANAGEMANET: 'User Management',
      USER_ROLE_MANAGEMANET: 'User Role Management',
      DEVICE_MANAGEMANET: 'Device Management',
      COUPON_MANAGEMANET: 'Coupon Management',
      SELECT_LOCATION: 'Select Location',
    },
    HEADER_MENU: {
      THEME: {
        LIGHT: 'Light',
        DARK: 'Dark',
        SYSTEM: 'System',
      },
      PROFILE_MENU: {
        LANGUAGE: 'Language',
        SWITCH_TO_USER: 'Switch To User',
        SIGNOUT: 'Sign Out',
      }
    },
    SIGNIN_MODULE: {
      SIGNIN: 'Sign In',
      EMAIL: 'Email',
      EMAIL_REQUIRED: 'Email is required',
      EMAIL_INVALID: 'Email is invalid',
      EMAIL_ATLEAST3_SYMBOLS: 'Email should have at least 3 symbols',
      EMAIL_MAX_VALIDATION: 'Email should have maximum 360 symbols',
      PHONE: 'Phone',
      PASSWORD: 'Password',
      PASSWORD_REQUIRED: 'Password is required',
      FORGOT_PASSWORD_LINK: 'Forgot Password ?',
    },
    FORGOT_PASSWORD_MODULE: {
      FORGOT_PASSWORD: 'Forgot Password',
      EMAIL: 'Email',
      PHONE: 'Phone',
      EMAIL_REQUIRED: 'Email is required',
      EMAIL_INVALID: 'Email is invalid',
      EMAIL_ATLEAST3_SYMBOLS: 'Email should have at least 3 symbols',
      EMAIL_MAX_VALIDATION: 'Email should have maximum 360 symbols',
      FORGOT_ERROR: 'Sorry, looks like there are some errors detected, please try again.',
      PASSWORD_RESET_LINK_SENT: 'A password reset link has been sent to your email. Please check your inbox.',
    },
    RESET_PASSWORD_MODULE: {
      RESET_PASSWORD: ' Reset Password',
      NEW_PASSWORD: 'New Password',
      NEW_PASSWORD_REQUIRED: 'New Password is required',
      PASSWORD_MUST_6CHAR_LONG: 'Password must be at least 6 characters long',
      PASSWORD_COMPLEXITY_REQUIREMENTS: 'Password should have at least one small alphabet,one capital alphabet,one digit and one special character',
      CONFIRM_PASSWORD: 'Confirm Password',
      CONFIRM_PASSWORD_REQUIRED: 'Confirm Password is required',
      PASSWORD_NOT_MATCH: 'Passwords do not match',
    },
    PLEASE_WAIT: 'Please wait...',
    SAVE_CHANGES: 'Save Changes',
    COUNTINUE: 'Continue',
    BACK: 'Back',
    SUBMIT: 'Submit',
    CANCEL: 'Cancel',
    EDIT: 'Edit',
    VIEW: 'View',
    DELETE: 'Delete',
    PAGES: {
      ROW_PER_PAGE: 'Row Per Page',
      TOTAL_RECORD: 'Total Records',
      NEXT: 'Next',
      PREVIOUS: 'Previous',
    },
    NO_RECORDS: 'No Records Available',
    YES: 'Yes',
    NO: 'No',
    VENDOR_PROFILE: {
      PROFILE: 'Profile',
      CHANGE_PASSWORD: 'Change password'
    },
    PROFILE_DETAILS_TAB: {
      PROFILE_DETAILS: 'Profile Details',
      NAME: 'Name',
      EMAIL_OR_PHONE: 'Email or Phone',
    },
    CHANGE_PASSWORD_TAB: {
      OLD_PASSWORD: 'Old Password',
      OLD_PASSWORD_REQUIRED: 'Old Password is required',
      NEW_PASSWORD: 'New Password',
      NEW_PASSWORD_REQUIRED: 'New Password is required',
      PASSWORD_MUST_6CHAR_LONG: 'Password must be at least 6 characters long',
      PASSWORD_COMPLEXITY_REQUIREMENTS: 'Password should have at least one small alphabet,one capital alphabet,one digit and one special character',
      CONFIRM_PASSWORD: 'Confirm Password',
      CONFIRM_PASSWORD_REQUIRED: 'Confirm Password is required',
      PASSWORD_NOT_MATCH: 'Passwords do not match',
    },
    USER_MANAGEMENT: {
      SEARCH_USERS: 'Search Users',
      USERS: 'Users',
      NEW_USER: 'New User',
      CONFIRM_DELETE_MSG: 'Are you sure do you want to delete this User?',
      USER_TABLE: {
        NAME: 'Name',
        EMAIL_PHONE: 'Email/Phone',
        ROLE: 'Role',
        ACTIONS: 'Actions',
      },
      VIEW_USERS: {
        USER_DETAIL: 'User Detail',
        NAME: 'Name',
        EMAIL: 'Email',
        ROLE_NAME: 'Role name',
        MODULE_WISE_ROLE_PERMISSION: 'Module Wise Role Permision',
        ADD: 'Add',
        EDIT: 'Edit',
        VIEW: 'View',
        DELETE: 'Delete',
      },
      ADD_USER: 'Add User',
      EDIT_USER: 'Edit User',
      DELETE_USER: 'Delete User',
      ADD_EDIT: {
        NAME: 'Name',
        EMAIL: 'Email',
        ROLE: 'Role',
        SELECT_ROLE: 'Select Role',
      }
    },
    USER_ROLE_MANAGEMENT: {
      USER_ROLES: 'User Roles',
      ADD_USER_ROLE: 'Add User Role',
      CONFIRM_DELETE_MSG: 'Are you sure do you want to delete this User Role?',
      ROLE_TABLE: {
        NAME: 'Name',
        DESCRIPTION: 'Description',
        ACTIONS: 'Actions',
      },
      DELETE_USER_ROLE: 'Delete User Role',
      ADD_ROLE: 'Add Role',
      EDIT_ROLE: 'Edit Role',
      ADD_EDIT: {
        NAME: 'Name',
        DESCRIPTION: 'Description',
        MODULE_WISE_ROLE_PERMISSION: 'Module Wise Role Permision',
        ADD: 'Add',
        EDIT: 'Edit',
        VIEW: 'View',
        DELETE: 'Delete',
      }
    },
    DEVICE_MANAGEMENT: {
      DEVICES: 'Devices',
      SEARCH_DEVICES: 'Search Devices',
      CONFIRM_DELETE_MSG: 'Are you sure do you want to delete this Device?',
      DEVICE_TABLE: {
        NAME: 'Name',
        STATUS: 'Status',
        BATTERY: 'Battery',
        LOCATION: 'Location',
        LAST_UPDATE: 'Last Update',
        ACTIONS: 'Actions',
      },
      ADD_EDIT: {
        NAME: 'Name',
        BATTERY: 'Battery',
        STATUS: 'Status',
        LOCATION: 'Location',
        SELECT_LOCATION: 'Select Location',
      },
      SELECT_STATUS: 'Select Status',
      ACTIVE: 'Active',
      INACTIVE: 'InActive',
      SORT_BY: 'Sort By',
      ASC: 'Asc',
      DESC: 'Desc',
      ADD_DEVICE: 'Add Device',
      EDIT_DEVICE: 'Edit Device',
      DELETE_DEVICE: 'Delete Device',
    },
    COUPON_MANAGEMENT: {
      COUPONS: 'Coupons',
      CONFIRM_DELETE_MSG: 'Are you sure do you want to delete this Coupon?',
      COUPON_TABLE: {
        TITLE: 'Title',
        CODE: 'Code',
        AMOUNT_PERCENT: 'Amount Or Percent Amount',
        COUPON_TYPE: 'Coupon Type',
        NUMBER_OF_USED: 'Number Of used',
        STATUS: 'Status',
        ACTIONS: 'Actions',
      },
      ADD_EDIT: {
        TITLE: 'Title',
        CODE: 'Code',
        AMOUNT_PERCENT: 'Amount Or Percent Amount',
        START_DATE: 'Start Date',
        END_DATE: 'End Date',
        NUMBER_OF_USE: 'Number Of use',
        TYPE_OF_AMOUNT: 'Type of Amount',
        AMOUNT: 'Amount',
        PLEASE_SELECT_BRANCH_LOCATION: 'Please select branch location from header',
      },
      SELECT_TYPE: 'Select Type',
      FIXED_AMOUNT: 'Fixed Amount',
      PERCENTAGE_AMOUNT: 'Percentage Amount',
      SELECT_COUPON_TYPE: 'Select Coupon Type',
      COUPON: 'Coupon',
      GIFT_CARD: 'Gift Card',
      FILTER_BY: 'Filter By',
      ALL: 'All',
      UTILIZED: 'Utilized',
      SELECT_STATUS: 'Select Status',
      ENABLE: 'Enable',
      DISABLE: 'Disable',
      ADD_COUPON: 'Add Coupon',
      EDIT_COUPON: 'Edit Coupon',
      DELETE_COUPON: 'Delete Coupon',
    }
  }
};
